<template>
  <v-container fluid
    ><v-form ref="form" @submit.prevent="search">
      <v-card class="mb-2">
        <v-card-actions>
          <v-text-field
            v-model="searchTerm"
            label="search"
            placeholder="Firstname, surname or NDIS Number"
          ></v-text-field>
          <v-spacer v-if="isAdmin"></v-spacer>

          <v-select
            v-if="isAdmin"
            label="State"
            v-model="deleted"
            :items="[
              { text: 'Active participants', value: false },
              { text: 'Deleted participants', value: true },
            ]"
            @change="toggleDeleted"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">Search</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-data-table
      id="list-table"
      ref="datatable"
      :headers="headers"
      :items="cases"
      :loading="loading"
      item-key="id"
      v-if="!deleted"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td>
            <v-avatar size="50px">
              <v-img
                v-if="props.item.participantDetails.photo"
                v-bind:src="props.item.participantDetails.photo.downloadURL"
                alt="Avatar"
                aspect-ratio="1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-icon
                v-if="!props.item.participantDetails.photo"
                color="green darken-2"
                size="50"
                >mdi-account-circle</v-icon
              >
            </v-avatar>
          </td>
          <td text-xs-left>{{ props.item.status }}</td>
          <td text-xs-left>{{ props.item.participantDetails.nDISNumber }}</td>
          <td text-xs-left>{{ props.item.participantDetails.firstname }}</td>
          <td text-xs-left>{{ props.item.participantDetails.lastname }}</td>
          <td text-xs-left>{{ props.item.participantDetails.email }}</td>
          <td text-xs-left>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  slot="activator"
                  small
                  class="mr-2"
                  v-on="on"
                  @click="editCase(props.item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Participant dashboard</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  slot="activator"
                  small
                  class="mr-2"
                  v-on="on"
                  @click="editCaseNotes(props.item)"
                  >mdi-calendar-text-outline</v-icon
                >
              </template>
              <span>General notes</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  slot="activator"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="editCaseGoals(props.item)"
                  >mdi-notebook</v-icon
                >
              </template>
              <span>Goals / Progress notes</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  slot="activator"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="editCaseAlerts(props.item)"
                  >mdi-bell-alert</v-icon
                >
              </template>
              <span>Alerts</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{ on }">
                <v-icon
                  slot="activator"
                  v-on="on"
                  small
                  class="mr-2"
                  @click="deleteItem(props.item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-data-table
      id="list-table"
      ref="datatable"
      :headers="deletedHeaders"
      :items="deletedCases"
      :loading="loading"
      item-key="id"
      v-if="deleted"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td>
            <v-avatar size="50px">
              <v-img
                v-if="props.item.participantDetails.photo"
                v-bind:src="props.item.participantDetails.photo.downloadURL"
                alt="Avatar"
                aspect-ratio="1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-icon
                v-if="!props.item.participantDetails.photo"
                color="green darken-2"
                size="50"
                >mdi-account-circle</v-icon
              >
            </v-avatar>
          </td>
          <td text-xs-left>{{ props.item.status }}</td>
          <td text-xs-left>{{ props.item.participantDetails.nDISNumber }}</td>
          <td text-xs-left>{{ props.item.participantDetails.firstname }}</td>
          <td text-xs-left>{{ props.item.participantDetails.lastname }}</td>
          <td text-xs-left>{{ props.item.participantDetails.email }}</td>
          <td>
            {{
              props.item.deletedOn == undefined
                ? ""
                : moment(props.item.deletedOn.toDate()).format("YYYY-MM-DD hh:mm")
            }}
          </td>

          <td>
            {{
              props.item.deletedBy == undefined ? "" : props.item.deletedBy.displayName
            }}
          </td>

          <td>
            <v-icon small @click="unDeleteItem(props.item)" v-if="isAdmin"
              >mdi-undo-variant</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-divider></v-divider>
    <v-card v-if="!isAvailable">
      <p>
        <v-icon color="warning">mdi-alert-circle</v-icon> Participant is not found or
        access denied
      </p>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import * as caseService from "../../services/caseService";
import * as reportService1 from "../../services/reportService1";
//import Message from 'primevue/message';

export default {
  name: "accounts-form",
  data: () => ({
    searchTerm: "",
    form: {},
    deleted: false,
    isAdmin: false,
    dialog: false,
    totalItems: 0,
    loading: false,
    isAvailable: true,
    uid: "",
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
    ],
    docs: [],
    deletedCases: [],
    headers: [
      { text: "Image", value: "avatar" },
      { text: "Status", value: "status" },
      { text: "NDIS Number", value: "nDISNumber" },
      { text: "Participant first name", value: "firstName" },
      { text: "Participant last name", value: "lastName" },
      { text: "Email address", value: "eMail" },
      { text: "", value: "actions" },
    ],
    deletedHeaders: [
      { text: "Image", value: "avatar" },
      { text: "Status", value: "status" },
      { text: "NDIS Number", value: "nDISNumber" },
      { text: "Participant first name", value: "firstName" },
      { text: "Participant last name", value: "lastName" },
      { text: "Email address", value: "eMail" },
      { text: "Deleted On", value: "deletedOn" },
      { text: "Deleted By", value: "deletedBy" },

      { text: "", value: "actions" },
    ],
  }),
  props: {
    msg: String,
  },
  mounted() {
    if (
      this.user.roles.indexOf("System Administrator") > -1 ||
      this.user.roles.indexOf("Organisation Administrator") > -1
    ) {
      this.isAdmin = true;
    }
    this.init();
  },
  computed: {
    cases() {
      return this.$store.getters.cases;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      if (
        (this.$utils.isEmpty(this.cases) || this.cases.length == 0) &&
        this.$utils.isEmpty(this.searchTerm)
      ) {
        this.search();
      }
    },
    moment: function (date) {
      return date != undefined ? moment(date) : moment();
    },
    toggleStatus() {
      if (this.deleted) {
        this.getDeleted();
      } else {
        this.getGoals();
      }
    },
    toggleDeleted() {
      if (this.deleted) {
        this.getDeleted();
      }
    },
    getActive() {
      let tempDocs = [];

      caseService.search(this.currentOrganisation.id, this.searchTerm).then((result) => {
        console.log(result);

        let docs = [];
        result.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          docs.push(data);
        });
        console.log(docs);
        console.log(this.cases);
        if (docs.length == 0) {
          this.$store.dispatch("setToast", {
            message: "Participant not found",
            color: "blue",
          });
        }

        for (let i = 0; i < docs.length; i++) {
          if (docs[i].signatures != undefined) {
            if (docs[i].signatures.authorizedUsers != undefined) {
              if (docs[i].signatures.authorizedUsers.length != 0) {
                for (let j = 0; j < docs[i].signatures.authorizedUsers.length; j++) {
                  this.uid = docs[i].signatures.authorizedUsers[j].id;
                  console.log(this.user.id);
                  console.log(this.uid);
                  if (this.user.id == this.uid || this.isAdmin ) {
                    tempDocs[i] = docs[i];
                  }else{
                    console.log(docs[i].id);
                  }
                }
              }
            }
          }

           if (this.isAdmin || this.uid == "") {
            tempDocs[i] = docs[i];
          }
           else if (docs.length == 1) {
            this.$store.dispatch("setToast", {
              message: "Access denied",
              color: "warning",
            });
          }

          this.uid = "";
        }
        console.log(tempDocs);

        this.docs = tempDocs.sort((ax, bx) => {
          if (ax.modifiedOn == undefined) {
            return 1;
          }
          if (bx.modifiedOn == undefined) {
            return -1;
          }
          let a = ax.modifiedOn.toDate();
          let b = bx.modifiedOn.toDate();
          return a > b ? -1 : a < b ? 1 : 0;
        });

        this.$store.dispatch("setCases", this.docs);
      });
    },
    getDeleted() {
      caseService
        .searchDeleted(this.currentOrganisation.id, this.searchTerm)
        .then((result) => {
          let cases = [];
          result.docs.map((doc) => {
            const casex = doc.data();
            casex.id = doc.id;
            cases.push(casex);
          });

          this.deletedCases = cases.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    deleteItem(item) {
      const me = this;
      const index = me.cases.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        caseService.remove(me.currentOrganisation.id, item.id, me.user).then((result) => {
          console.log(result);
          me.cases.splice(index, 1);
          this.$store.dispatch("setCases", me.cases);
        });
         reportService1
        .getBudgetItemsTotalsReport1(me.currentOrganisation.id)
        .then((doc) => {
          let casesReport = doc.data().rows;
          for(let i=0;i<casesReport.length;i++)
          {
            
            if(casesReport[i].participantId==item.id)
            {
             console.log(casesReport[i]); 
              reportService1
                    .deleteReport(me.currentOrganisation.id, casesReport[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
            }
          }
       
        })

      }
    },
    unDeleteItem(item) {
      const me = this;
      const index = me.deletedCases.indexOf(item);

      caseService.unRemove(me.currentOrganisation.id, item.id).then((result) => {
        console.log(result);
        me.cases.push(item);
        me.deletedCases.splice(index, 1);
        this.$store.dispatch("setCases", me.cases);
      });
    },
    search() {
      if (this.deleted) {
        this.getDeleted();
      } else {
        this.getActive();
      }
    },
    editCase(data) {
      this.$store.commit("setRecentCases", data);
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/participant-management/current-participant");
    },
    editCaseNotes(data) {
      this.$store.commit("setRecentCases", data);
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/general-notes");
    },
    editCaseGoals(data) {
      this.$store.commit("setRecentCases", data);
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/goals");
    },
    editCaseAlerts(data) {
      this.$store.commit("setRecentCases", data);
      this.$store.commit("setCurrentCase", data);
      this.$router.push("/participant-management/alerts");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
